<script setup lang="ts">
// icons
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

import dayjs from '@/utils/day';

/********************
 * PROPS & EMITS    *
 ********************/
export interface CiAvailabilityInfoProps {
  availability: object;
  campsite?: any;
  primary?: boolean;
  snowplowContext: object;
}
const props = withDefaults(defineProps<CiAvailabilityInfoProps>(), {
  campsite: () => {},
  primary: false,
});

/********************
 * COMPOSITIONS      *
 ********************/
const appStore = useAppStore();
const backendStore = useBackendStore();
const localePath = useLocalePath();
const route = useRoute();
const { $ngettext, interpolate, $gettext } = useGettext();
const { locale } = useI18n();
const { mapRating } = useRating();
const { openModal, closeModal } = useCiModal();
const { trackAdClickAndFollow: DTrackAdClickAndFollow } = useTracking();

/********************
 * REFS & VARS       *
 ********************/
const showDescription = ref(false);

const campsiteSlug = computed(() => {
  return props.campsite?.slug || '';
});

const campingCardDiscount = computed(() => {
  return (
    getFirstDiscountFromAvailability(props.availability, DISCOUNT_TYPE_CAMPING_CARD)
    || getFirstDiscountFromAvailability({ choices: [props.availability] }, DISCOUNT_TYPE_CAMPING_CARD)
  );
});

const hasCampingCardDiscount = computed(() => {
  return (
    availabilityHasDiscount(props.availability, DISCOUNT_TYPE_CAMPING_CARD)
    || availabilityHasDiscount({ choices: [props.availability] }, DISCOUNT_TYPE_CAMPING_CARD)
  );
});

const accoType = computed(() => {
  if (props.availability.acco_type) {
    return props.availability.acco_type;
  }

  if (props.availability.pitch) {
    return 'pitch';
  }
  if (props.availability.acco) {
    return 'acco';
  }
  return '';
});

const bookingUrl = computed(() => {
  return `${backendStore.url}/external-link/?lang=${locale.value}&slug=${encodeURIComponent(
    campsiteSlug.value,
  )}&url=${encodeURIComponent(funnelUrl.value)}&link_identifier=campsite-detail-availability-funnel`;
});

const funnelUrl = computed(() => {
  let url;
  if (!hasCampingCardDiscount.value) {
    url = props.availability.funnelUrl;
  } else {
    url = campingCardDiscount.value?.meta?.funnelUrl || props.availability.funnelUrl;
  }
  if (
    url.match(/^https:\/\/checkout\.camping\.info/)
    || url.match(/^https:\/\/[a-z0-9_-]+-jvbf-frontend\.test\.acsicamping\.info/)
    || url.match(/^https:\/\/checkout\.acsicamping\.info/)
  ) {
    const translated = $ngettext('%{count} rating', '%{count} ratings', props.campsite.rating_guest.rating_count);
    const numRatingsLabel = interpolate(translated, {
      count: props.campsite.rating_guest.rating_count,
    });

    const regions = props.campsite.regions.map(region => ({
      name: region.name,
      slug_en: region.slugs.en,
    }));

    const city = {
      name: props.campsite?.meta?.city_obj?.name || props.campsite?.meta?.city,
      slug_en: props.campsite?.meta?.city_obj?.slugs?.en || null,
    };

    const country = {
      name: props.campsite?.meta?.country.name,
      slug_en: props.campsite?.meta?.country.slugs.en || null,
    };

    const additionalData = {
      campsiteData: {
        id: props.campsite.id,
        camping_id: props.campsite.camping_id,
        name: props.campsite.meta.name,
        slug: props.campsite.slug,
        purchases: props.campsite.purchases,
        location: {
          country,
          city,
          regions,
        },
        rating: {
          count: {
            value: props.campsite.rating_guest.rating_count,
            label: numRatingsLabel,
          },
          value: props.campsite.rating_campsite.rating_avg_overall,
          label: mapRating(props.campsite.rating_campsite.rating_avg_overall),
        },
        award: props.campsite.awards.length > 0,
      },
    };

    const encryptedData = encrypt(additionalData);
    if (encryptedData) {
      url = `${url}&init=${encryptedData}`;
    }
  }
  return url;
});

const hasDetails = computed(() => {
  return (
    props.availability.description
    || props.availability.meta?.includedPersons
    || props.availability.meta?.maximumPersons
    || props.availability.meta?.minimumPersons
  );
});

const i18nShowDetails = computed(() => {
  return showDescription.value ? $gettext('hide details') : $gettext('show details');
});

const includedOptions = computed(() => {
  return props.availability.meta?.includedExtras?.filter(extra => !extra.isPerson);
});

/********************
 * INITIALIZATION    *
 ********************/
if ((campsiteSlug.value === '' || !campsiteSlug.value) && !props.availability.funnelUrl) {
  console.warn('Snippet has no campsite slug and no funnel url!');
}

/********************
 * FUNCTIONS         *
 ********************/
function getCurrency(data) {
  if (!data.currency) {
    return 'EUR';
  }
  if (typeof data.currency === 'object') {
    return data.currency.code || 'EUR';
  }
  return data.currency || 'EUR';
}

function getFormattedDate(date) {
  if (!date) {
    return;
  }
  return dayjs(date).format('DD.MM.YY');
}

function getDayForDate(date, format) {
  if (!date) {
    return;
  }
  return dayjs(date).format(format);
}

function getDetailQueryParams({ type, from, until, context } = { type: null, from: null, until: null, context: null }) {
  const query = { ...route.query };
  if (!query) {
    return {};
  }

  delete query.only_availabilities;
  delete query.offset;
  delete query.q;

  if (!type) {
    return query;
  } else {
    query.acco_type = type;
  }

  if (from) {
    query.from = from;
  }

  if (until) {
    query.until = until;
  }

  if (context) {
    query.context = context;
  }

  return { ...query };
}

function onBooknowClicked(event) {
  gtagReportConversion('Mu2WCJbqjc8DEKKErIID');
  trackAdClickAndFollow(event);
  if (hasCampingCardDiscount.value) {
    event.preventDefault();
    showCampingCardModal();
  }
}

function trackAdClickAndFollow(event) {
  DTrackAdClickAndFollow(event, {
    bannerId: props.snowplowContext.bannerId || undefined,
    context: props.snowplowContext.contexts,
    impressionId: props.snowplowContext.impressionId,
    zoneId: props.snowplowContext.zone,
  });
}

function clickAvailability(event) {
  gtagReportConversion('NIiqCJPqjc8DEKKErIID');
  appStore.clickContext = 'availability';
  trackAdClickAndFollow(event);
}

function showCampingCardModal() {
  const CiCampingCardBooknowModal = defineAsyncComponent({
    loader: () => import('@/components/CiCampingCardModal/CiCampingCardBooknowModal.vue'),
  });

  openModal(
    {
      component: CiCampingCardBooknowModal,
      attrs: {
        name: 'CiCampingCardBooknowModal',
        funnelUrl: bookingUrl.value,
        campsiteSlug: campsiteSlug.value,
        onClose: () => {
          closeModal('CiCampingCardBooknowModal');
        },
      },
    },
    {
      clickToClose: true,
      contentTransition: 'translate-y-down',
      contentClass: 'w-full max-w-[660px] p-0 items-center overflow-hidden',
    },
  );
}
</script>

<template>
  <div class="row no-gutters mb-8 last:mb-0 md:mb-4 md:last:mb-0">
    <div
      v-if="hasCampingCardDiscount || props.availability.type === 'special-offer'"
      class="col-12 col-md-9"
    >
      <div class="row no-gutters">
        <div class="col-1" />
        <div class="col-11">
          <div class="mx-2">
            <span
              v-if="hasCampingCardDiscount"
              class="ml-1 inline-block rounded-[10rem] bg-[#00a4ee] px-[7px] py-[3px] text-xs font-medium leading-3 text-white"
            >CampingCard ACSI</span>
            <span
              v-if="props.availability.type === 'special-offer'"
              class="ml-1 inline-block rounded-[10rem] bg-danger px-[7px] py-[3px] text-xs font-medium leading-3 text-white"
            >{{ $gettext('special offer') }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-9 mb-2 md:mb-0">
      <div class="row no-gutters">
        <div class="col-1">
          <div class="flex h-full items-start justify-center">
            <img
              v-if="accoType === 'pitch'"
              src="~/assets/svg/icon-pitch.svg"
              width="26"
              height="auto"
              alt="icon pitch"
              loading="lazy"
            >
            <img
              v-else
              src="~/assets/svg/icon-cabin.svg"
              width="26"
              height="auto"
              alt="icon cabin"
              loading="lazy"
            >
          </div>
        </div>

        <div class="col-11">
          <div class="row">
            <div class="col-12 col-md-8">
              <div class="mx-2 flex h-full flex-col">
                <div class="flex flex-row items-end gap-1 text-sm">
                  <span class="font-medium">{{ getFormattedDate(props.availability.start_date) }}</span>
                  <span class="text-small-book text-gray">{{
                    getDayForDate(props.availability.start_date, 'dd')
                  }}</span>
                  -
                  <span class="font-medium">{{ getFormattedDate(props.availability.end_date) }}</span>
                  <span class="text-small-book pr-2 text-gray">{{
                    getDayForDate(props.availability.end_date, 'dd')
                  }}</span>
                  <span class="text-small-book whitespace-nowrap text-gray">
                    {{
                      $ngettext('(%{ count } night)', '(%{ count } nights)', props.availability.duration, {
                        count: props.availability.duration,
                      })
                    }}
                  </span>
                </div>

                <template v-if="props.availability.stock && props.availability.stock > 0">
                  <template v-if="accoType === 'pitch'">
                    <div
                      v-if="props.availability.stock <= 5"
                      class="text-xs text-danger"
                    >
                      {{
                        $ngettext(
                          'Only %{stock} pitch available',
                          'Only %{stock} pitches available',
                          props.availability.stock,
                          { stock: props.availability.stock },
                        )
                      }}
                    </div>
                    <div
                      v-else
                      class="text-xs text-gray"
                    >
                      {{ $gettext('%{stock} pitches available', { stock: props.availability.stock }) }}
                    </div>
                  </template>
                  <template v-else>
                    <div
                      v-if="props.availability.stock <= 5"
                      class="text-xs text-danger"
                    >
                      {{
                        $ngettext(
                          'Only %{stock} rental accommodation available',
                          'Only %{stock} rental accommodations available',
                          props.availability.stock,
                          { stock: props.availability.stock },
                        )
                      }}
                    </div>
                    <div
                      v-else
                      class="text-xs text-gray"
                    >
                      {{ $gettext('%{stock} rental accommodations available', { stock: props.availability.stock }) }}
                    </div>
                  </template>
                </template>

                <div
                  v-if="props.availability.label"
                  class="text-xs"
                >
                  {{ props.availability.label }}
                </div>

                <div
                  v-if="hasDetails"
                  class="text-xs"
                >
                  <template v-if="showDescription">
                    <div v-if="props.availability.description">
                      {{ props.availability.description }}
                    </div>
                    <template v-if="includedOptions?.length">
                      {{ $gettext('Included extras') }}: <span v-for="extra in includedOptions" :key="extra.label" class="mr-1 after:content-[','] last-of-type:mr-0 last-of-type:after:content-none">
                        <span v-if="extra.quantity > 1">{{ extra.quantity }}x </span>
                        {{ extra.label }}
                      </span>
                    </template>
                    <div v-if="props.availability.meta?.minimumPersons">
                      <span>{{ $gettext('Min persons') }}</span>: {{ props.availability.meta.minimumPersons }}
                    </div>
                    <div v-if="props.availability.meta?.maximumPersons">
                      <span>{{ $gettext('Max persons') }}</span>: {{ props.availability.meta.maximumPersons }}
                    </div>
                    <div v-if="props.availability.meta?.includedPersons">
                      <span>{{ $gettext('Included persons') }}</span>: {{ props.availability.meta.includedPersons }}
                    </div>
                  </template>
                  <div
                    class="flex cursor-pointer flex-row items-center text-primary"
                    @click="showDescription = !showDescription"
                  >
                    <span>{{ i18nShowDetails }}</span>
                    <CiAwesomeIcon
                      class="ml-1 fill-primary"
                      ratio="0.5"
                      :icon="showDescription ? faChevronUp : faChevronDown"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="props.availability.price"
              class="col-12 col-md-4"
            >
              <div class="m-2 flex flex-row flex-wrap items-end md:m-0 md:mr-6 md:flex-col">
                <span
                  class="text-input-book mr-2 whitespace-nowrap md:mr-0"
                  :class="{ 'text-danger': props.availability.price < props.availability.basePrice }"
                >
                  <span>{{ $pgettext('Campsite Tile availability price', 'from') }}</span>
                  {{
                    new Intl.NumberFormat(locale, {
                      style: 'currency',
                      currency: getCurrency(props.availability),
                    }).format(props.availability.price)
                  }}</span>
                <span
                  v-if="props.availability.price < props.availability.basePrice"
                  class="mr-2 whitespace-nowrap text-xs line-through md:mr-0"
                >
                  {{
                    new Intl.NumberFormat(locale, {
                      style: 'currency',
                      currency: getCurrency(props.availability),
                    }).format(props.availability.basePrice)
                  }}</span>
                <span class="text-small-book text-gray">{{
                  $pgettext('Campsite Tile price per stay', 'per stay')
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-3">
      <a
        v-if="props.availability.funnelUrl"
        class="button w-full"
        :class="{ 'button--primary-outline': !props.primary, 'button--primary': props.primary }"
        :href="bookingUrl"
        target="_blank"
        rel="noopener"
        @click="onBooknowClicked"
      >
        <span>{{ $gettext('Book now') }}</span>
      </a>
      <nuxt-link
        v-else
        class="button icon-absolute w-full"
        :class="{ 'button--primary-outline': !props.primary, 'button--primary': props.primary }"
        :to="
          localePath({
            name: RBN_CAMPSITE_DETAIL,
            params: { identifier: campsiteSlug },
            query: getDetailQueryParams({
              from: props.availability.start_date,
              type: props.availability.acco_type,
              until: props.availability.end_date,
            }),
            hash: '#availabilities',
          })
        "
        @click.capture="clickAvailability"
      >
        <span>{{ $pgettext('Campsite Tile choose availability', 'Choose') }}</span>
        <CiAwesomeIcon
          :icon="faChevronRight"
          ratio="0.6"
        />
      </nuxt-link>
    </div>
  </div>
</template>

<style></style>
